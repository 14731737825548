import { Breakpoint, Color, Shape, ShapeVariant, StyledComponent } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import { FontWeight } from '../../../../theme';

import { ImgWrapperProps } from './success.types';

export const Container: StyledComponent = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content: StyledComponent = styled.div`
  padding: 64px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${Breakpoint.SmallDesktop} {
    padding: 100px 70px 0;
  }
`;

export const StyledShape: StyledComponent = styled(Shape)`
  display: block;
  position: absolute;
  top: 0;
  right: -40px;
  width: 180px;
  height: auto;

  @media ${Breakpoint.Tablet} {
    width: 276px;
    right: 80px;
  }
`;

export const ImgWrapper: StyledComponent<ImgWrapperProps> = styled.div<ImgWrapperProps>`
  position: relative;
  width: 100%;
  padding-top: 60px;
  margin-top: auto;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    padding-top: 110px;

    ${({ $shape }: ImgWrapperProps): false | RuleSet<ImgWrapperProps> => $shape === ShapeVariant.Cage && css`
      padding-top: 200px;

      ${StyledShape} {
        width: 310px;
      }
    `}
  }
`;

export const Img: StyledComponent = styled.img`
  display: block;
  width: 100%;
`;

export const Headline: StyledComponent = styled.p`
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  line-height: 1.8;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${Color.ToyotaSignatureRed};
`;

export const Text: StyledComponent = styled.p`
  font-size: 24px;
  line-height: 1.3;
  color: ${Color.Black100};
  max-width: 100%;
  word-wrap: break-word;
  max-width: 732px;

  @media ${Breakpoint.SmallDesktop} {
    font-size: 30px;
    line-height: 1.25;
  }
`;

export const Span: StyledComponent = styled.span`
  color: ${Color.Gray500};
`;
