import { Breakpoint, Color, Shape, StyledComponent } from '@elearning-platform/ui';
import { styled } from 'styled-components';

export const Container: StyledComponent = styled.div`
  background-color: ${Color.Black100};
  min-height: 100vh;
`;

export const Grid: StyledComponent = styled.div`
  padding: 0 16px 16px;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 113px);
  grid-template-rows: 85px 1fr min-content;
  max-width: 1280px;
  margin: 0 auto;

  @media ${Breakpoint.SmallDesktop} {
    padding: 0 80px 80px;
    grid-template-columns: 50% 200px 1fr;
    grid-template-rows: 156px 1fr min-content;
  }
`;

export const EmptyShape: StyledComponent = styled.div`
  border-top: 1px solid ${Color.Gray600};
  border-bottom: 1px solid ${Color.Gray600};
  border-left: 1px solid ${Color.Gray600};
  width: 100%;
  position: relative;
  bottom: -1px;
`;

export const StyledShape: StyledComponent = styled(Shape)`
  width: 100%;
  height: 85px;
  flex-shrink: 0;
  border: 1px solid ${Color.TSR100};
  position: relative;
  bottom: -1px;

  @media ${Breakpoint.SmallDesktop} {
    height: 156px;
  }
`;

export const TextBox: StyledComponent = styled.div`
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding: 64px 20px;
  border-bottom: 1px solid ${Color.Gray600};
  border-left: 1px solid ${Color.Gray600};
  border-right: 1px solid ${Color.Gray600};

  @media ${Breakpoint.SmallDesktop} {
    padding: 40px;
    border-right: none;
  }
`;

export const MainText: StyledComponent = styled.p`
  font-size: 30px;
  line-height: 1.25;
  color: ${Color.Gray100};

  @media ${Breakpoint.SmallDesktop} {
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: -0.72px;
  }
`;

export const Span: StyledComponent = styled.span`
  color: ${Color.TSR100};
  display: block;
`;

export const HelperText: StyledComponent = styled.p`
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.8;
  color: ${Color.Gray400};
`;

export const ShapeBoxes: StyledComponent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -1px;
  border-bottom: 1px solid ${Color.Gray600};
`;

export const StyledShape2: StyledComponent = styled(Shape)`
  width: 100%;
  height: 192px;
  flex-shrink: 0;
  border: 1px solid ${Color.TSR100};
`;

export const StyledShape3: StyledComponent = styled(Shape)`
  width: 100%;
  height: 192px;
  flex-shrink: 0;
  border: 1px solid ${Color.Gray600};
  border-top: none;
  border-bottom: none;
`;

export const ButtonWrapper: StyledComponent = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${Color.Gray600};
  border-left: 1px solid ${Color.Gray600};
  border-right: 1px solid ${Color.Gray600};
  width: fit-content;

  @media ${Breakpoint.SmallDesktop} {
    padding: 40px;
    grid-column: 1 / 3;
    width: 100%;
  }
`;

export const EmptyDiv: StyledComponent = styled.div``;
