import { Breakpoint, Color, StyledComponent } from '@elearning-platform/ui';
import { css, RuleSet, styled } from 'styled-components';

import { TextProps } from './header.types';

export const Container: StyledComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;

  @media ${Breakpoint.SmallDesktop} {
    padding: 24px 40px;
  }
`;

export const LogoImg: StyledComponent = styled.img`
  display: block;
  width: 46px;
  height: auto;

  @media ${Breakpoint.SmallDesktop} {
    width: 51px;
  }
`;

export const ButtonWrapper: StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Text: StyledComponent<TextProps> = styled.p<TextProps>`
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: ${Color.Gray700};

  ${({ $light }: TextProps): false | RuleSet<TextProps> => $light && css`
    color: ${Color.Gray400};
  `}
`;
