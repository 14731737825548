import { Breakpoint, Button, ButtonSize, ButtonTheme, IconName } from '@elearning-platform/ui';
import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { AppConfig } from '../../../../appConfig';
import Logo from '../../../../images/logo.svg';
import LogoLight from '../../../../images/logoLight.svg';

import { HeaderVariant } from './header.enums';
import { ButtonWrapper, Container, LogoImg, Text } from './header.styled';
import { HeaderProps } from './header.types';

export const Header: React.FC<HeaderProps> = ({ variant }: HeaderProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isDark: boolean = variant === HeaderVariant.Dark;

  return (
    <Container>
      <LogoImg src={isDark ? LogoLight : Logo} />
      <ButtonWrapper>
        {!!isTablet && (
          <Text $light={isDark}>{t('emailVerification.contactText')}</Text>
        )}
        <Button
          href={`mailto:${AppConfig.platformContactEmail}`}
          icon={IconName.EmailOutlined}
          label={t('emailVerification.help')}
          reversed
          size={ButtonSize.Medium}
          variant={isDark ? ButtonTheme.SecondaryWhite : ButtonTheme.SecondaryGray}
        />
      </ButtonWrapper>
    </Container>
  );
};
