import { ShapeVariant } from '@elearning-platform/ui';
import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Fail } from './components/fail/fail.component';
import { Success } from './components/success/success.component';
import { EmptyDiv } from './emailVerification.styled';
import ConfirmEmailBgLarge from './images/confirmBgLarge.jpg';
import ConfirmEmailBgSmall from './images/confirmBgSmall.jpg';
import EmailVerifiedBgLarge from './images/verifiedBgLarge.jpg';
import EmailVerifiedBgSmall from './images/verifiedBgSmall.jpg';
import { EmailVerificationActions } from './redux/emailVerification.actions';
import { selectIsTokenValid } from './redux/emailVerification.selectors';

export const EmailVerification: React.FC = (): JSX.Element => {
  const { token }: Params = useParams();
  const { t }: TransProps<never> = useTranslation();
  const isTokenValid: unknown = useSelector(selectIsTokenValid);
  const isFetchingData: boolean = isTokenValid === null;
  const dispatch: Dispatch<AnyAction> = useDispatch();

  useEffect((): () => void => {
    if (token) {
      dispatch(EmailVerificationActions.checkToken(token));
    }

    return (): void => {
      dispatch(EmailVerificationActions.clearData());
    };
  }, [token, dispatch]);

  if (!token) {
    return (
      <Success
        headline={t('emailVerification.confirmRegistration')}
        imageLarge={ConfirmEmailBgLarge}
        imageSmall={ConfirmEmailBgSmall}
        primaryText={t('emailVerification.emailSent')}
        secondaryText={t('emailVerification.clickLink')}
        shape={ShapeVariant.Circle1}
      />
    );
  }

  if (isFetchingData) {
    return <EmptyDiv />;
  }

  if (isTokenValid) {
    return (
      <Success
        headline={t('emailVerification.registrationConfirmed')}
        imageLarge={EmailVerifiedBgLarge}
        imageSmall={EmailVerifiedBgSmall}
        primaryText={t('emailVerification.requestReceived')}
        secondaryText={t('emailVerification.accountConfirmation')}
        shape={ShapeVariant.Cage}
      />
    );
  } else {
    return <Fail />;
  }
};
