import { all, fork } from "redux-saga/effects";

import { watchEmailVerification } from "../pages/emailVerification/redux/emailVerification.watchers";
import { watchCommon } from "../redux/common/common.sagas";
import { watchAppSettings } from "../appSettings/redux/appSettings.sagas";
import { watchUserContext } from "../userContext/redux/userContext.sagas";
import { watchUserRegistration } from "../userRegistration/redux/userRegistration.sagas";
import { watchLesson } from "../lesson/redux/lesson.sagas";
import { watchWebsockets } from "../redux/websocket/websocket.sagas";
import { sagas } from "../modules";

export default function* rootSaga() {
  yield all([
    fork(watchEmailVerification),
    fork(watchCommon),
    fork(watchAppSettings),
    fork(watchUserContext),
    fork(watchUserRegistration),
    fork(watchLesson),
    fork(watchWebsockets),
    ...sagas.map(fork),
  ]);
}
